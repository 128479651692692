import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { hasValue } from "@/common/utilities/hasValue";
import Button from "@/component-library/components/buttons/button/Button";
import { wideLocationCardAlternative1Style } from "@/component-library/components/cards/location-cards/wide-location-card-alternative-1/WideLocationCardAlternative1.css";
import {
    wideLocationCardContentHeaderStyle,
    wideLocationCardContentHeaderTitleStyle,
    wideLocationCardContentStyle,
    wideLocationCardCTAStyle,
    wideLocationCardImageStyle,
    wideLocationCardLocationDescriptionStyle,
    wideLocationCardLocationNameStyle,
    wideLocationCardOperatorNameStyle,
    wideLocationCardPricingStyle,
    wideLocationCardRatingStyle,
} from "@/component-library/components/cards/location-cards/wide-location-card-shared-styles.css";
import { ConvertedCurrencyComponent } from "@/component-library/components/currency/converted-currency/ConvertedCurrencyComponent";
import Tooltip from "@/component-library/components/feedback/tooltip/Tooltip";
import MapMarkerIcon from "@/component-library/components/icons/MapMarkerIcon";
import ExpandingText from "@/component-library/components/layout/expanding-text/ExpandingText";
import Separator from "@/component-library/components/layout/separator/Separator";
import RemoteImage from "@/component-library/components/media/remote-image/RemoteImage";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { DealTag } from "@/component-library/components/text/tags/deal-tag/DealTag";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { TPictureSchema } from "@/features/host-locations/domain/entities/schemas/PictureSchema";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import Link from "next/link";
import React, { HTMLAttributes, ReactNode } from "react";

interface WideLocationCardAlternative1Props
    extends HTMLAttributes<HTMLDivElement> {
    options: {
        locationPageHref: string;
        ctaLabel: ReactNode;
        dealLabel?: ReactNode;
        locationDetails: {
            name: string;
            operator: {
                name: string | NoValue;
            };
            currency: {
                code: string;
            };
            description?: ReactNode;
            address?: ReactNode;
        };
        picture: {
            pictureData: TPictureSchema | NoValue;
            pictureIsLoading?: boolean;
        };
        rating: string | NoValue;
        ratingNumber: number | NoValue;
        pricing: {
            totalPriceWithoutVat: number | NoValue;
            hourlyPriceWithoutVat: number;
        };
        priority?: boolean;
    };
}

const WideLocationCardAlternative1: React.FC<
    WideLocationCardAlternative1Props
> = ({ options, ...restProps }) => {
    return (
        <Flex
            direction={"row"}
            className={clsx(wideLocationCardAlternative1Style)}
            gap={0}
            {...restProps}
        >
            <Link
                href={options.locationPageHref}
                target="_blank"
                className={wideLocationCardImageStyle}
                style={assignInlineVars({
                    flex: `0 0 30em`,
                })}
            >
                <RemoteImage
                    fallbackCaption={"Image of the location"}
                    imageResolution={{
                        widthInPx: 400,
                        heightInPx: 400 / (16 / 9),
                    }}
                    pictureData={options.picture.pictureData ?? undefined}
                    keepInLoadingState={
                        options.picture.pictureIsLoading ?? false
                    }
                    priority={options.priority}
                    quality={100}
                />

                {options.dealLabel && (
                    <DealTag
                        style={assignInlineVars({
                            position: `absolute`,
                            top: `0.5rem`,
                            left: `0.5rem`,
                        })}
                    >
                        <Tooltip
                            tooltip={`This location has a deal! Open the page to find out more.`}
                        >
                            {options.dealLabel}
                        </Tooltip>
                    </DealTag>
                )}
            </Link>
            <Flex
                direction={"column"}
                className={wideLocationCardContentStyle}
                alignItems={"start"}
            >
                <Link
                    href={options.locationPageHref}
                    target="_blank"
                    style={assignInlineVars({
                        textDecoration: `none`,
                        overflow: `hidden`,
                    })}
                >
                    <Flex
                        direction={"row"}
                        className={wideLocationCardContentHeaderStyle}
                    >
                        <div
                            className={wideLocationCardContentHeaderTitleStyle}
                        >
                            {options.locationDetails.operator.name && (
                                <div
                                    className={
                                        wideLocationCardOperatorNameStyle
                                    }
                                >
                                    {options.locationDetails.operator.name}
                                </div>
                            )}
                            <Flex
                                direction="row"
                                alignItems="center"
                                gap={Magnitudes.spacingInRem.xxxs}
                                style={assignInlineVars({
                                    overflow: `hidden`,
                                    marginTop: hasValue(
                                        options.locationDetails.operator.name
                                    )
                                        ? `-0.25em`
                                        : undefined,
                                    whiteSpace: `nowrap`,
                                })}
                            >
                                <MapMarkerIcon
                                    pathProps={{
                                        style: assignInlineVars({
                                            fill: theme.colors.semantic
                                                .interactive.default,
                                        }),
                                    }}
                                    style={assignInlineVars({
                                        flex: `0 0 auto`,
                                        display: `block`,
                                    })}
                                />
                                <div
                                    className={
                                        wideLocationCardLocationNameStyle
                                    }
                                >
                                    {options.locationDetails.name}
                                </div>
                            </Flex>
                            <div
                                style={assignInlineVars({
                                    //color: theme.colors.text.secondary,
                                    fontSize: `${Magnitudes.fontInRem.xs}rem`,
                                    fontVariationSettings: `"wght" 400`,
                                    overflow: `hidden`,
                                    textOverflow: `ellipsis`,
                                })}
                            >
                                {options.locationDetails.address}
                            </div>
                        </div>
                    </Flex>
                </Link>

                <ExpandingText
                    href={options.locationPageHref}
                    initialHeight={90}
                    target={"_blank"}
                >
                    <div className={wideLocationCardLocationDescriptionStyle}>
                        {options.locationDetails.description}
                    </div>
                </ExpandingText>
            </Flex>
            <Separator variant="verticalLight" />
            <Flex
                direction={"column"}
                className={wideLocationCardContentStyle}
                alignItems={"stretch"}
                justifyContent={"end"}
            >
                <Flex
                    direction={"row"}
                    justifyContent={"end"}
                    style={assignInlineVars({
                        color: theme.colors.semantic.interactive.default,
                        fontWeight: `500`,
                        fontSize: `${Magnitudes.fontInRem.m}rem`,
                        marginBottom: `auto`,
                    })}
                >
                    <div>
                        {ratingToNamedScale(options.ratingNumber || undefined)}
                    </div>
                    <div className={wideLocationCardRatingStyle}>
                        {hasValue(options.rating) ? options.rating : "–"}
                    </div>
                </Flex>

                <Flex
                    direction={"column"}
                    alignItems={"flex-end"}
                    className={wideLocationCardPricingStyle}
                    gap={0}
                >
                    <div>Starting from</div>
                    {options.pricing.totalPriceWithoutVat ? (
                        <div>
                            <VisuallyHidden>Total price is </VisuallyHidden>
                            <ConvertedCurrencyComponent
                                amount={options.pricing.totalPriceWithoutVat}
                                currency={options.locationDetails.currency.code}
                            />
                        </div>
                    ) : (
                        <div>
                            <VisuallyHidden>
                                The minimum price is{" "}
                            </VisuallyHidden>
                            <ConvertedCurrencyComponent
                                amount={options.pricing.hourlyPriceWithoutVat}
                                currency={options.locationDetails.currency.code}
                            />
                        </div>
                    )}
                </Flex>

                <Button
                    as={Link}
                    href={options.locationPageHref}
                    target="_blank"
                    className={wideLocationCardCTAStyle}
                >
                    {options.ctaLabel}
                </Button>
                {/*</Flex>*/}
            </Flex>
        </Flex>
    );
};

/**
 * Converts a rating to a named scale, input is a number between 0 and 10
 * @param rating - The rating to convert
 */
const ratingToNamedScale = (rating?: number) => {
    if (rating === undefined) {
        return "";
    }
    if (rating < 2) {
        return "Poor";
    }
    if (rating < 4) {
        return "Fair";
    }
    if (rating < 6) {
        return "Average";
    }
    if (rating < 8) {
        return "Good";
    }
    if (rating < 9) {
        return "Very good";
    }
    if (rating < 10) {
        return "Excellent";
    }
    return "Perfect";
};

export default WideLocationCardAlternative1;
